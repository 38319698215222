body {
  background-color: #f5f5f6;
  font-family: din-2014;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

input {
  border: none;
  outline: none;
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}
// COLORS VARIABLES
$grey-67: rgba(0, 0, 0, 0.67);
$pink-97: #ff9797;
$pink-eb: #ffebeb;
$grey-e4: #ece4e4;
$grey-9b: #9b9b9b;
$black-0e: #0e0e0e;

// MIXINS
@mixin font($font-size, $font-weight, $font-color) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $font-color;
}

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

.text-center {
  text-align: center;
}
.img-responsive {
  width: 100%;
}

.br-bottom-20 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.br-top-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

div.grecaptcha-badge {
  display: none !important;
}

.pink-text-gradient {
  background-image: linear-gradient(180deg, transparent 77%, #fccbcb 0);
  background-size: 100% 86%;
  background-repeat: no-repeat;
  transition: background-size 0.4s;
  display: inline-block;
  padding: 0 2px;
}

.primary-btn {
  @include font(14px, bold, white);
  @extend .text-center;
  width: 100%;
  padding: 14px;
  background-color: #000;
  border-radius: 2px;
}
.mobile-wrapper {
  padding: 0 15px;
  background-color: white;
  height: 100vh;
  @include flex(center, center);
  flex-direction: column;

  .content-wrapper {
    width: 100%;
    margin-bottom: 50px;
    .went-wrong-message {
      width: 320px;
      height: 108px;
      font-family: D-DIN;
      font-size: 16px;
    }
  }
}

.input-wrapper {
  position: relative;
  span {
    position: absolute;
    left: 10px;
    top: -8px;
    padding: 2px;
    background-color: white;
    @include font(11px, normal, #3e3e3e);
  }
  input {
    width: 100%;
    border-radius: 2px;
    border: solid 1px #dedede;
    background-color: rgba(0, 0, 0, 0);
    padding: 13px;
    @include font(14px, bold, #292929);
  }
}

// ********************************************
// COPY URL

.wipe-out-wrapper {
  padding: 22px 15px;
  // margin-top: 50px;
  margin-bottom: 10px;
  background-color: white;
  p {
    @include font(16px, normal, #000);
    margin-bottom: 0;
  }
  h2 {
    @include font(16px, bold, #000);
    margin-bottom: 25px;
  }
}

.use-above-fold {
  padding: 30px 10px 50px;
  background-color: white;
  h1 {
    @extend .pink-text-gradient;
    @include font(21px, bold, #000);
    text-transform: capitalize;
  }

  .banner-wrapper {
    position: relative;
    .banner {
      @extend .img-responsive;
      width: 253px;
      height: 128px;
      border-radius: 8px;
      // box-shadow: 0 0 12px #9c9999;
      border: 3px solid #9c9999;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
    .play-btn {
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.offers-wrapper {
  background-color: white;

  p {
    @include font(14px, normal, #000);
    padding-top: 15px;
    margin-bottom: 0;
  }
  h2 {
    @include font(14px, bold, #000);
    margin-bottom: 25px;
  }

  .code-wrapper {
    border: solid 1px #8a8787;
    padding: 11px 0 14px;
    h2 {
      @include font(29px, bold, #000);
      margin-top: 0;
      margin-bottom: 10px;
    }
    .code {
      @include flex(center, center);
      height: 33px;
      border-radius: 3px;
      input {
        @include font(14px, normal, #000);
        @include flex(center, center);
        background: white;
        height: 33px;
        margin: 0;
        padding: 0;
        border: dotted 1px #8a8787;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: none;
        text-align: center;
        width: 110px;
      }
      button {
        border: none;
        border: dotted 1px #8a8787;
        height: 35px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    h3 {
      font-size: 11px;
      margin-bottom: 2px;
    }
    h4 {
      @include font(11px, normal, #000);
      margin-top: 10px;
      margin-bottom: 2px;
    }
    h5 {
      @include font(8px, normal, #000);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.top-picks-wrapper {
  background-color: white;
  .title {
    padding: 20px 16px;
    h6 {
      @extend .pink-text-gradient;
      @include font(16px, bold, #000);
      margin: 0;
    }
    p {
      @include font(13px, normal, $grey-67);
      margin-bottom: 0;
    }
  }

  .products {
    a {
      text-decoration: none;
    }
    .product {
      @include flex(center, center);
      border-top: solid 2px #f4f4f4;
      padding: 10px;
      padding-bottom: 15px;
      .left {
        flex: 1;
        position: relative;
        .rating {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: white;
          border-radius: 1.4px;
          border: solid 0.5px #e0e0e0;
          padding: 3px;
          @include flex(center, center);
          span {
            @include font(12px, bold, #000);
          }
          img {
            width: 10px;
            height: 10px;
            margin-left: 2px;
          }
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
      .right {
        flex: 2;
        h2 {
          @include font(12px, bold, #000);
          height: 28px;
        }
        h3 {
          @include font(11px, normal, $grey-67);
          height: 28px;
        }
        .shade-wrapper {
          @include flex(flex-start, center);
          height: 12px;
          p {
            @include font(10px, bold, #000);
            margin: 0;
            margin-left: 5px;
          }
          h4 {
            @include font(10px, bold, $grey-9b);
            margin: 0;
          }
        }
        .price-wrapper {
          @include flex(flex-start, center);
          margin-top: 10px;
          h6 {
            @include font(16px, bold, #000);
            margin: 0;
          }
          p {
            margin: 0;
            margin-left: 5px;
            @include font(11px, normal, $grey-9b);
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .see-more-btn {
    @include font(16px, normal, $grey-67);
    width: 100%;
    border-top: solid 2px #f4f4f4;
    padding: 15px 0;
    background-color: white;
  }
}

.bottom-banner {
  margin-top: 20px;
}

// MOBILE ROUTE

.mobile-number-section {
  .scanning-img {
    width: 90px;
    height: 71px;
    margin-bottom: 21px;
    margin-left: 12px;
  }
  // img {
  //   width: 80px;
  //   height: 74px;
  //   margin-bottom: 21px;
  // }
  h3 {
    @include font(20px, bold, #000);
  }
  h4 {
    @include font(18px, bold, #4a4a4a);
    margin-top: 0;
    margin-bottom: 43px;
  }

  .input-wrapper {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    button {
      text-transform: uppercase;
    }
  }
}

// OTP

.otp-section {
  .otp-inputs-wrapper {
    input {
      margin: 10px;
      width: 40px;
      border-bottom: solid 1px black;
      @include font(16px, bold, black);
      @extend .text-center;
    }
  }
  .content-wrapper {
    p {
      @include font(16px, normal, #4a4a4a);
      margin-bottom: 50px;
    }
    h3 {
      @include font(14px, bold, #000);
      margin-bottom: 50px;
    }
    .resend-otp-btn {
      @include font(12px, bold, #000);
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
}

.navbar {
  @include flex(flex-start);
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  height: 50px;
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  a {
    height: 50px;
    @include flex();
    margin-right: 10px;
    margin-left: 15px;
    .back-icon {
      height: 32px;
    }
  }

  .brand-logo {
    height: 32px;
  }
}

.qr-not-found {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100px;
  position: absolute;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.btn-loader {
  height: 30px;
}

.error-msg-otp {
  display: block;
  color: red;
  font-size: 12px;
}
.header-spacing {
  margin-top: 50px;
}

// otp
.input-wrapper.input-glammpoints {
  position: relative;
}

.myglamm-coin {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  img {
    margin-left: 3px;
  }
}
